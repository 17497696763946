<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { sliders } from "@/config/api/sliders";
import SliderModal from "@/components/sliders/slider-modal";

/**
 * Slider Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    SliderModal,
  },
  data() {
    return {
      title: "Sliders",
      items: [
        {
          text: "Sliders",
          active: true,
        },
        {
          text: "",
        },
      ],
      sliderData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "url", sortable: true, label: "Slider URL", default: "N/A" },
        { key: "image", sortable: true, label: "Slider Image" },
        { key: "createdAt", sortable: true, label: "Date Created" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
  },
  mounted() {
    // Set the initial number of items
    this.loadData();
  },
  methods: {
    hideActionModal() {
      this.$bvModal.hide("action-Certificate");
    },
    showActionModal() {
      this.$bvModal.show("action-Certificate");
    },
    /**
     * Search the table data with search input
     */
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    updatePage(page) {
      this.loadData(page);
    },
    loadData(page = 1, search = "") {
      const api = sliders.model.get;
      api.params = { page: page, search: search };
      this.generateAPI(api).then((res) => {
        this.sliderData = res.data.sliders.docs;
        this.totalRows = res.data.sliders.totalDocs;
      });
    },
    onRowClicked(item) {
      this.$router.push({
        path: "/slider-info",
        query: { id: item._id },
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button
              id="addSlider"
              pill
              variant="primary"
              @click="showActionModal"
              >Add Slider</b-button
            >

            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All sliders</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table
                    tbody-tr-class="rowClass"
                    class="table-centered"
                    :items="sliderData"
                    :fields="fields"
                    responsive="sm"
                    :busy="$store.state.api.loading"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    @row-clicked="onRowClicked"
                  >
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader
                          color="#505d69"
                          :loading="true"
                        ></pulse-loader>
                      </div>
                    </template>

                    <template v-slot:cell(image)="row">
                      <b-img
                        thumbnail
                        class="slider-image"
                        :src="row.value"
                        width="50"
                      ></b-img>
                    </template>

                    <template v-slot:cell(url)="row">
                      {{ row.value || "Not provided" }}
                    </template>
                    <template v-slot:cell(createdAt)="row">
                      {{ moment(row.value).format('LLL')  }}
                    </template>
                    <template v-slot:cell(action)="row">
                      <router-link
                        :to="{
                          path: '/slider-info',
                          query: { id: row.item._id },
                        }"
                      >
                        <i class="ri-eye-line font-size-18"></i
                      ></router-link>

                      <router-link
                        :to="{
                          path: '/edit-slider',
                          query: { id: row.item._id },
                        }"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i
                      ></router-link>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          @change="updatePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <SliderModal v-on:reloadData="loadData" v-on:closeModal="hideActionModal" />
  </Layout>
</template>

<style scoped>
#addSlider {
  float: right;
  margin-top: 10px;
}
.slider-image {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
</style>
