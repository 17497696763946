<script>
import { sliders } from "@/config/api/sliders";
import { getFormData } from "@/utils/createForm";
export default {
  data() {
    return {
      values: {},
      image: null,
      performAction: false,
      schema: [

      ],
    };
  },
  methods: {
    async submitHandler() {
        const formdata = getFormData({
          file: this.image.files[0].file,
        });
        this.addSlider(formdata);
      
    },
    addSlider(data) {
      this.performAction = true;

      const api = sliders.model.create;
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Slider added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Slider is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    
  },
  
};
</script>

<template>
  <b-modal
    id="action-Certificate"
    @hidden="$emit('resetModal')"
    title='Add Slider'
    hide-footer
  >
    <FormulateForm
      name="CertificateAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput
        type="image"
        name="Image"
        v-model="image"
        label="Select slider image to upload"
        help="Select a png or jpg to upload."
        validation="required:mime:image/jpeg,image/png,image/webp"
      />
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Adding... </span>
        </span>
        <span v-else>
          <span> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>


<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>